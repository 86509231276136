/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@angular/cdk/overlay-prebuilt.css';

// @import '@shared/themes/life-blue/styles/theme.scss';

@import '@shared/assets/styles/global.scss';

// Use for overriding default HTML elements
body { 
    margin: 0;
    // background-color: $theme-colour-background;
    background-color: #FFF;
    color: $colour-primary;
}

// Fix for Virtual Keyboard on iOS where page does not scroll down
// again after keyboard is minimized.
// app-root[aria-hidden="true"] {
//     position: fixed;
// }

a {
    color: $theme-colour-accent;
    text-decoration: none;
    font-weight: 600;
}

h1 {
    font-weight: 600;
}

// input {
//     height: 20px;
// }

// Make the keypad a global dialog box.
._keypad {
    .mat-dialog-container {
        width: 472px;
        height: 528px;
        background: #FFF;
        padding: 40px;
        border-radius: 16px;
    }
}

._keypad-bg {
    background-color: rgba(0, 0, 0, 0.7);
}