// Block
.customPanel {
    width: 100%;
    overflow: hidden;

    // Element
    ._panelHeadline {
        padding: 0 22px 22px;
        margin-bottom: 0;
        font-weight: 600;
        letter-spacing: 1.29px;
        line-height: 24px;
        text-transform: uppercase;
        border-bottom: 1px solid $colour-shadow;
    }

    // Element
    th, ._th {
        margin-top: 10px;
        color: $colour-accent;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 19px;
        text-transform: uppercase;
    }

    td, ._td {
        height: 30px;
        padding-right: 10px;
        margin-bottom: 10px;
        color: $colour-primary;
        font-size: 18px;
        line-height: 30px;
        white-space: nowrap;
    }

    // Element
    ._dynamicText {
        font-weight: 700;

        // Modifier
        &.-urgent {
            color: #D0021B;
        }
    }

    // Modifier
    .-left {
        text-align: left;
    }

    // Modifier
    .-center {
        text-align: center;
        margin: auto;
    }

    // Modifier
    .-right {
        text-align: right;
        padding-right: 0;
        padding-left: 10px;
    }

    // Modifier
    tr.-last,
    tr.-last td {
        height: 0 !important;
    }
}

// Block
.customPanel {
    // Modifier
    &.-divide {
        tr td {
            border: 0;
            border-bottom: 1px solid $theme-colour-primary;
        }
    }

    // Modifier
    &.-divide-2 {
        tr td {
            border: 0;
        }
        tr:nth-child(even) td {
            border-bottom: 1px solid $theme-colour-primary;
        }
    }

    // Modifier
    &.-divide-n {
        tr td {
            border: 0;
        }
        tr.-first td {
            border-top: 1px solid $theme-colour-primary;
        }
        tr:first-of-type td {
            border: 0;
        }
        tr:last-of-type td {
            border-bottom: 1px solid $theme-colour-primary;
        }
    }
}
