@use '@angular/material' as mat;

@include mat.core();

@import 'palette';

// Theme colours
$theme-colour-background: mat.get-color-from-palette($mat-palette-primary, 100);

$theme-colour-primary: mat.get-color-from-palette($mat-palette-primary, 400);
$theme-colour-primary-contrast: mat.get-contrast-color-from-palette($mat-palette-primary, 400);

$theme-colour-accent: mat.get-color-from-palette($mat-palette-primary, A100);
$theme-colour-accent-contrast: mat.get-contrast-color-from-palette($mat-palette-primary, A100);


// Standard colours
$colour-shadow: mat.get-color-from-palette($mat-palette-grey, 100);
$colour-background: mat.get-color-from-palette($mat-palette-grey, 50);

$colour-primary: mat.get-color-from-palette($mat-palette-grey, 500);
$colour-accent: mat.get-color-from-palette($mat-palette-grey, 300);


// Temporary variables
// Since for some reason the components SCSS are loaded before the theme is processed
// We cannot load the theme here since it should not be called multiple times per build
