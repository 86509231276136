// Block
// Data tables
.customTableContainer {
    margin: 22px 22px 100px;
}

// Block
.customTable {
    width: 100%;

    // Element
    tr {
        height: 60px !important;

        // Element
        td {
            text-align: center;
            font-size: 20px !important;
            font-weight: 400;
            color: $colour-primary !important;
            white-space: nowrap;

            // Modifier
            &.-smaller {
                font-size: 0.8em !important;
            }
        }
        
        // Element
        a {
            color: $colour-primary;
        }

        // Modifier
        &.-group {
            height: 40px !important;
            td {
                background-color: $theme-colour-background !important;
                font-size: 14px !important;
                font-weight: 600 !important;
                text-transform: uppercase;
            }
        }

        // Modifier
        &.-spacer {
            height: 10px !important;
            td {
                height: 10px !important;
                background-color: $colour-background;
                border-bottom: none;
            }
            
        }
    }

    // Element
    th, th button {
        font-size: inherit;
        letter-spacing: 1px;
        text-align: center !important;
        text-transform: uppercase;
        &.-left {
            text-align: left !important;
        }
    }

    th button {
        padding-left: 20px;
    }

    // Element
    th:first-child, th:first-child button,
    td:first-child {
        text-align: left !important;
    }

    // Element
    ._indicator {
        width: 32px;
        height: 59px;
        margin-left: -24px;
        margin-right: 10px;
        background-color: #CFD8DC;
    
        // Modifier
        &.-notice {
            background-color: #FCB1B1;
        }

        // Modifier
        &.-urgent {
            background-color: #D0021B;
        }

        // Modifier
        &.-completed {
            background-color: $theme-colour-accent !important;
        }

        // Modifier
        &.-online {
            background-color: #d1f2af;
        }

        // Modifier
        &.-inactive {
            background-color: #ffd897;
        }

        // Modifier
        &.-offline {
            background-color: #fcb1b1!important;
        }

    }

    // Element
    ._dynamicText {
        font-weight: 700;

        // Modifier
        &.-notice {
            color: #FCB1B1;
        }

        // Modifier
        &.-urgent {
            color: #D0021B;
        }

        // Modifier
        &.-intervention {
            color: #AEBCC4;
        }

        // Modifier
        &.-no-file {
            color: #6B808B;
        }

        // Modifier
        &.-completed {
            color: $theme-colour-accent;
        }

        // Modifier
        &.-lurking {
            color: #FCB1B1;
        }

        // Modifier
        &.-lodging {
            color: #D0021B;
        }
    }

    // Modifier
    &.-smaller {
        th, th button,
        td, td a, td ._dynamicText {
            font-size: 16px !important;
        }
    }

    // Modifier
    // Draws a thick line at the top of each new dataset
    &.-dividers {
        tr.-first td {
            border-top: 5px solid $theme-colour-primary;
            margin-top: 20px;
        }
    }
    
}
