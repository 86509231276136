// Block
.customMenu {

    // Element
    ._menuHeadline {
        padding: 10px;
        font-size: 15px;
        font-weight: 600;
        color: $colour-primary;
        border-bottom: 1px solid $colour-shadow;
    }

    // Element
    a,
    ._customMenuItem {
        font-weight: normal;
        color: $colour-primary;
        .mat-icon {
            margin-right: 20px;
        }
    }

     // Modifier
     &.-primary {
        ._customMenuItem:hover {
            color: $theme-colour-primary;
            background-color: $theme-colour-background;
        }
    }

}

// Element
._customMenuItem {

   
}
