// Element
._customLoading {
    margin: 22px;
    color: $theme-colour-primary;
}

// Element
._customEmpty {
    margin: 22px;
    color: $colour-accent;
}

// Element
._customError {
    border: 1px solid red;
    border-radius: 5px;
    background-color: #FF00001A;
    padding: 10px;
}

// Block {
.customNotice {
    width: 572px;
    min-height: 50px;
    border: 1px solid $theme-colour-primary;
    border-radius: 3px;
    background-color: $theme-colour-background;
    padding: 8px;

    // Element
    &__headline {
        color: $theme-colour-primary;
        font-size: 15px;
        letter-spacing: 1.17px;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    // Element
    &__list {
        list-style: none;
        padding: 0;
        margin: 4px 0;
    }
}
