// Block
.customChipContainer {
    margin: 7px -4px !important;
}

.customChipMenu {
    padding: 5px;
}

// Element
._customChip {
    font-size: 11px;
    min-height: 14px;
    text-transform: uppercase;

    // Default
    border-radius: 10px;
    padding: 0 10px;
    margin-left: 8px;

    &.-larger {
        border-radius: 20px;
        font-size: 0.9em !important;
        padding: 4px 12px 4px 8px;
    }

    // Modifier
    &.-primary {
        min-height: inherit;
        color: #FFF !important;
        background-color: $theme-colour-primary !important;
    }

    &.-accent {
        min-height: inherit;
        color: #FFF !important;
        background-color: $theme-colour-accent !important;
    }

    // Modifier
    &.-secondary {
        min-height: inherit;
        color: $theme-colour-primary !important;
        background-color: $theme-colour-background !important;

    }

    // Modifier
    &.-warn {
        min-height: inherit;
        color: #FFF !important;
        background-color: #D0021B !important;
    }

    // Modifier
    &.-warn.-secondary {
        color: #D0021B !important;
        background-color: #FCB1B1 !important;
    }

    // Modifier
    &.-disabled {
        min-height: inherit;
        color: #6B808B !important;
        background-color: #EBF1F4 !important;
    }

    &.-wca {
        min-height: inherit;
        color: #168BF5;
        background-color: #ADD6E4;

        &.-no-paperwork {
            color: #D0021B;
            background-color: #FCB1B1;
        }

        &.-paperwork-incomplete {
            color: #D0021B;
            background-color: #EBF1F4;
        }
    }

    // Modifier
    // @TODO Rename to something more generic
    &.-paeds {
        min-height: inherit;
        color: #525B60 !important;
        background-color: #FFE6C7 !important;
    }

    &.-follow-up {
        min-height: inherit;
        color: #168BF5;
        background-color: #ADD6E4;
    }

    // Modifier
    &.-me {
        min-height: inherit;
        color: #FFF;
        background-color: #36CA36!important;
    }

    // Modifier
    &.-triage {
        min-height: inherit;
        color: #525B60 !important;
        background-color: #AAE6AB;
    }

    // Modifier
    &.-role,
    &.-treatment {
        min-height: inherit;
        color: #525B60 !important;
        background-color: #FADB7C;
    }

    // Modifier
    &.-complete {
        min-height: inherit;
        color: #525B60 !important;
        background-color: #FBBF7D;
    }

    // Modifier
    &.-reception {
        min-height: inherit;
        color: #FFF;
        background-color: #525B60;
    }

    // Modifier
    &.-admin {
        min-height: inherit;
        color: #FFF;
        background-color: #D0021B;
    }

    &.-non-covid {
        min-height: inherit;
        color: #FFF;
        background-color: #36CA36;
    }

    &.-pui {
        min-height: inherit;
        color: #525B60;
        background-color: #FFDB71;
    }

    &.-covid-positive {
        min-height: inherit;
        color: #FFF;
        background-color: #D0021B;
    }

    &.-attend {
        min-height: inherit;
        color: #525B60;
        background-color: #ffb3d4;
    }

    &.-specialist {
        min-height: inherit;
        color: #525B60;
        background-color: #ffb3d4;
    }

    .-larger & {
        font-size: 12px;
        min-height: 26px;
    }

    mat-icon {
        color: inherit !important;
    }
}
