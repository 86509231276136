@import 'variables';
@import 'utils';
@import 'custom-tabs';
@import 'custom-triage';
@import 'custom-table';
@import 'custom-button';
@import 'custom-chips';
@import 'custom-form';
@import 'custom-panel';
@import 'custom-menu';
@import 'custom-notice';
@import 'custom-breadcrumbs';

/** BLOCKS */

/** ELEMENTS */


/** TEXT MODIFIERS */

// Modifier
// Text colour
.-primary {
    color: $colour-primary !important;
    &-theme { color: $theme-colour-primary !important }
}
.-accent {
    color: $colour-accent !important;
    &-theme {color: $theme-colour-accent !important }
}
.-warn {
    color: #f44336 !important;
    &-theme {color: #f44336 !important }
}

// Modifier
// Emphasize
.-em {
    font-size: larger;
    font-weight: bolder;
}

.-lighter { font-weight: lighter !important }
.-bolder { font-weight: bolder !important }

.-bold {
    font-weight: 600 !important;
    &-700 { font-weight: 700 !important }
}

.-larger {
    font-size: 1.2em !important;
}

button.-larger {
    width: 60px;
    height: 60px;
    mat-icon {
        line-height: 34px !important;
        height: 34px;
        width: 34px;
        font-size: 34px;
    }
}

mat-icon.-larger {
    line-height: 34px !important;
    height: 34px;
    width: 34px;
    font-size: 34px !important;
}

.-smaller { font-size: 0.8em !important }

.-x {
    &-large { font-size: x-large !important }
    &x-large { font-size: xx-large !important }
    &-small { font-size: x-small !important }
    &x-small { font-size: xx-small !important }
}

// Modifier
// Align
.-left { text-align: left !important }
.-right { text-align: right !important }
.-center { text-align: center !important }

// Modifier
// Transform
.-lowercase { text-transform: lowercase }
.-uppercase { text-transform: uppercase }
.-capitalise { text-transform: capitalize }

// Modifier
.-no {
    &-wrap {
        white-space: nowrap;
    }
}

/** LAYOUT MODIFIERS */

// Modifier
// Size
.-xl {
    width: 100%;
    max-width: 960px;
}

// Modifier
// Size
.-lg {
    width: 100%;
    max-width: 640px;
}


// Modifier
// Size
.-md {
    width: 100%;
    max-width: 300px;
}

// Modifier
// Size
.-sm {
    width: 100%;
    max-width: 160px;
}

// Modifier
// Size
.-xs {
    width: 100%;
    max-width: 80px;
}

// Modifier
// Size
.-xxs {
    width: 100%;
    max-width: 40px;
}

// Modifier
// Outer padding
.-offset {
    margin: 8px;

    &-top {
        @include offset('top', 8);
        &-10 { @include offset('top', 10) }
        &-20 { @include offset('top', 20) }
        &-50 { @include offset('top', 50) }
        &-100 { @include offset('top', 100) }
        &--10 { @include offset('top', -10) }
    }

    &-right {
        @include offset('right', 8);
        &-10 { @include offset('right', 10) }
        &-20 { @include offset('right', 20) }
        &-30 { @include offset('right', 30) }
    }

    &-bottom {
        @include offset('bottom', 8);
        &-10 { @include offset('bottom', 10) }
        &-20 { @include offset('bottom', 20) }
        &-30 { @include offset('bottom', 30) }
        &-50 { @include offset('bottom', 50) }
        &--10 { @include offset('bottom', -10) }
    }

    &-left {
        @include offset('left', 8);
        &-10 { @include offset('left', 10) }
        &-20 { @include offset('left', 20) }
    }

}

// Modifier
// Inner padding
.-pad {
    padding: 2px;
    &-bottom {
        padding-bottom: 2px;
        &-10 {
            padding-bottom: 10px;
        }
        &-20 {
            padding-bottom: 20px;
        }
    }
}

// Modifier
// Reset values, mostly for responsive
.-reset {
    &-100 {
        width: 100%;
        min-width: 0;
        max-width: 100%;
        height: 100%;
        min-height: 0;
        max-height: 100% !important;
    }

    &-width {
        width: auto;
        min-width: min-content;
        max-width: max-content;

        &-100 { width: 100% !important }
    }

    &-height {
        height: auto !important;
        min-height: min-content;
        max-height: max-content !important;

        &-100 { height: 100% !important }
    }

    &-offset {
        margin: 0;

        &-top { @include offset('top', 0) }
        &-right { @include offset('right', 0) }
        &-bottom { @include offset('bottom', 0) }
        &-left { @include offset('left', 0) }
    }
}

/** STYLE MODIFIERS */

// Modifier
.-fainter { opacity: 0.54 }

// Modifier
.-elevate { box-shadow: 0 0 5px 3px $colour-shadow }

// Modifier
.-rounded { border-radius: 5px }

// Modifier
.-cigar {
    border-radius: 25px;
    padding: 0 10px;
}

// Modifier
.-oval { border-radius: 50% }

// Modifier
.-stroke { border: 1px solid }

// Modifier
.-pointer { cursor: pointer }

// Modifier
// Used by the screensaver service
.-blur {
    filter: blur(4px);
    height: 100%;
}

// Used by the keypad service
.-lock {
    filter: blur(4px);
    height: 100%;
}

// Modifier
.-background {
    &-white { background-color: white }
    &-black { background-color: black }
    &-primary {
        background-color: $colour-background;
        &-theme { background-color: $theme-colour-primary }
    }
    &-accent {
        background-color: $theme-colour-background;
        &-theme { background-color: $theme-colour-accent }
    }
}

// Modifier
.-content-box {
    box-sizing: content-box !important;
}
