// Element
// Triage chips
._customTriage {
    width: 65px;
    height: 30px;
    border: 0;
    border-radius: 50px;
    box-sizing: content-box;
    margin: 5px;
    outline: 0;

    // Modifier
    &.-none {
        display: none;
    }

    // Modifier
    &.-green {
        background-color: #36CA36;

        // 2nd tier modifier
        &.-selected {
            margin: 0;
            border: 5px solid #AAE6AB !important;
        }
    }

    // Modifier
    &.-yellow {
        background-color: #FDBD00;

        // 2nd tier modifier
        &.-selected {
            margin: 0;
            border: 5px solid #FADB7C !important;
        }
    }

    // Modifier
    &.-orange {
        background-color: #FF8602;

        // 2nd tier modifier
        &.-selected {
            margin: 0;
            border: 5px solid #FBBF7D !important;
        }
    }

    // Modifier
    &.-red {
        background-color: #F00;

        // 2nd tier modifier
        &.-selected {
            margin: 0;
            border: 5px solid rgb(245, 130, 95) !important;
        }
    }

    // Modifier
    &.-unknown {
        background-color: #CFD8DC; 
    }
}
