// Block
.customTabs {
    margin-top: 9px;

    //Modifier
    a {
        color: $colour-accent;
        font-size: 16px;
        text-transform: uppercase;
        opacity: 1 !important;
        box-shadow: inset 0 0 4px 0 $colour-shadow;

        &:first-child {
            border-radius: 5px 0 0;
        }

        &:last-of-type {
            border-radius: 0 5px 0 0;
        }

    }

    // Modifier
    &.-smaller a {
        font-size: smaller;
        min-width: 120px;
        padding: 2px;

        .customBadge {
            margin-left: 10px;
        }
    }

    // Modifier
    .-active {
        background-color: $theme-colour-primary;
        color: $theme-colour-primary-contrast;
    }
}

// Block
.customBadge {
	height: 30px;
    width: 30px;
    margin: 0 -10px 0 20px;
	border-radius: 3px;
	background-color: #D0021B;
    box-shadow: inset 0 0 3px 0 #78909C;

    // Element
    ._text {
        color: white;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.57px;
        text-align: center;
    }
}
