// Block
.customBreadcrumbs {
    // Element
    ._crumb {
        position: relative;
        margin-top: 10px;
    }
    // Element
    label {
        color: $theme-colour-primary;
        font-size: 16px;
        font-weight: bold;
    }
    // Element
    ._dot {
        border-radius: 50%;
        height: 11px;
        width: 11px;
        border: 4px solid rgba($theme-colour-primary, 0.41);;
        background-color: #FFFFFF;
        margin: 4px;
        background-clip: content-box;
        &.-solid {
            height: 16px;
            width: 16px;
            background-color: $theme-colour-primary;
            border-width: 6px;
            margin: 0;
        }
    }
    // Element
    ._dash {
        position: absolute;
        top: 11px;
        left: 22px;
        width: calc(100% + 47px);
        height: 6px;
        background-color: rgba($theme-colour-primary, 0.41);
    }
    // Element
    ._value {
        color: #78909C;
        font-size: 18px;
        line-height: 24px;
    }

}
