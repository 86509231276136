// Element
._customButton {
    border-radius: 24px !important;
    width: 146px;
    min-width: auto !important;
    text-transform: uppercase;

    // Modifier
    &.-stroke:not(:disabled) {
        border: 1px solid !important;
    }

    // Modifier
    &.-solid:not(:disabled) {
        background-color: $theme-colour-primary;
        color: $theme-colour-primary-contrast;
    }

    // Modifier
    &.-accent:not(:disabled) {
        background-color: $theme-colour-background;
        color: $theme-colour-primary !important;
    }
}

// Element
._customFAB {
    color: $colour-accent !important;
    box-shadow: 0 0 4px 2px rgba($colour-accent, 0.2);

    &.-primary {
        color: white !important;
        background-color: $theme-colour-primary;
    }
    &.-accent {
        color: $theme-colour-accent !important;
        background-color: white;
    }
}

// Element
._customAction {
    height: 40px;
    width: 160px;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 5px 0 $colour-shadow;
    color: $colour-accent;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;

    // Modifier
    &.-stroke:not(:disabled) {
        border: 1px solid !important;
    }
}

// Block
.customFilter {
    height: 54px;

    // Element
    ._input {
        height: 100%;
        background-color: white;
        border-radius: 5px 0 0 5px;
        border: 1px solid rgba($colour-accent, 0.54);
        padding: 12px 10px 8px;

        input {
            height: 100%;
            outline: 0;
            border: none;
            font-weight: lighter;
            font-size: larger;
            color: $colour-accent;
        }
    }

    // Element
    ._button {
        height: 100%;
        width: 172px;
        font-size: 18px;
        border-radius: 0 5px 5px 0;
    }
}

// Block
.customIncrement {
    margin-top: 4px;

    ._minus {
        height: 50px;
        border-radius: 5px 0 0 5px;
        color: $theme-colour-primary-contrast;
        background-color: $theme-colour-primary;
    }

    ._input {
        height: 50px;
        font-size: larger;
        text-align: center;
        border: none;
        border-radius: 0;
    }

    ._plus {
        height: 50px;
        border-radius: 0 5px 5px 0;
        color: $theme-colour-primary-contrast;
        background-color: $theme-colour-primary;
    }
}
