// Block
.customForm {
    $root: &;
    display: flex;
    flex: 1;

    // Element
    h1 {
        margin-top: 0;
    }

    // Element
    ._formHeadline {
        width: 100%;
        margin: 0;
        color: $colour-accent;
        letter-spacing: 1px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 2px solid $colour-background;

        &.-for-dialog {
            width: calc(100% + 49px);
            margin: -25px -25px 0;
            padding-left: 25px;
        }
    }

    // Element
    label, ._label {
        width: 20%;
        padding-bottom: 18px;
        font-weight: bolder;
        &.-reset-width {
            width: auto;
        }
    }

    // Modifier
    &.-portrait {
        label, ._label {
            width: auto;
            padding-bottom: 0;
        }
    }

    // Element
    ._required {
        color: #F44336;
    }

    .-error {
        margin-left: 20px;
        color: #F44336;
    }
}

// Block
.customRadioContainer {

    // Element
    button,
    ._button,
    ._toggle {
        height: 40px;
        width: 100%;
        min-width: 100px;
        background-color: $theme-colour-primary;
        border: 1px solid $theme-colour-primary;
        color: white;
        font-size: 16px;
        cursor: pointer;
        outline: 0;

        // Modifier
        &.-green {
            background-color: #36CA36;
        }

        // Modifier
        &.-yellow {
            background-color: #FDBD00;
        }

        // Modifier
        &.-orange {
            background-color: #FF8602;
        }

        // Modifier
        &.-red {
            background-color: #F00;
        }

        // Modifier
        &.-stroke {
            background-color: white;
            color: $theme-colour-primary;
            &:disabled {
                background-color: white;
            }
        }

        // Modifier
        &:first-of-type {
            border-radius: 3px 0 0 3px;
            margin-right: -1px;
        }

        // Modifier
        &:last-of-type {
            border-radius: 0 3px 3px 0;
            margin-left: -1px;
        }

        // Modifier
        &:disabled {
            cursor: default;
            border-color: rgba(black, 0.42);
            color: rgba(black, 0.42);
            background-color: rgba($theme-colour-primary, 0.42);
        }
    }

}

._customBadge {
    height: 40px;
    padding: 6px 8px;
    color: $theme-colour-primary;
    background-color: $theme-colour-background;
    border: 1px solid $colour-shadow;
    border-radius: 3px;
    font-weight: 600;
    white-space: nowrap;
    text-transform: none;
}

// Block
.alternateRadio {
    border: 1px solid #333;
    border-radius: 20px;

    .mat-icon {
        padding-left: 10px;
    }

    // Element
    &._triage {
        padding: 5px;

        // Element
        ._balance {
            width: 34px;
        }

        // Modifier
        &.-orange {
            border-color: #FF8602;
            color: #FF8602;
            &.-selected {
                background-color: #FF8602;
                color: #FFF;
            }
        }

        // Modifier
        &.-red {
            border-color: #D0021B;
            color: #D0021B;
            &.-selected {
                background-color: #D0021B;
                color: #FFF;
            }
        }
    }

    // Element
    &._button {
        border-color: #CDD7DC;
        color: #CDD7DC;
        padding: 5px 25px 5px 0 !important;
        margin: 2px;

        // Modifier
        &.-selected {
            background-color: #E1F5FC;
            border-color: #03A7E7;
            color: #03A7E7;
        }
    }
}

// Element
._checkmark {
    visibility: hidden;

    // in Modifier
    .-selected & {
        visibility: visible;
    }
}
